import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { useStyletron } from 'baseui'
import { HeadingXSmall, LabelMedium, LabelSmall } from 'baseui/typography'
import { Tab, Tabs } from 'baseui/tabs'
import { Button, KIND, SHAPE, SIZE as ButtonSize } from 'baseui/button'
import { SIZE } from 'baseui/tag'
import { Block } from 'baseui/block'
import FlexBlock from '../../components/FlexBlock'
import ClientDetail from '../../components/Clients/Detail'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { createNote, deleteNote, deletePaper, detailPaper, listNotes, updateNote, updatePaper } from '../../core/apis'
import Layout from '../../components/Layout'
import { TriangleDown } from 'baseui/icon'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'baseui/modal'
import WeakButton from '../../components/WeakButton'
import BorderedButton from '../../components/BorderedButton'
import { StatefulMenu } from 'baseui/menu'
import { PLACEMENT, StatefulPopover } from 'baseui/popover'
import { sinceFromNow, statusToStep } from '../../core/utils'
import routes from '../../routes'
import { useSnackbar } from 'baseui/snackbar'
import { Spinner } from 'baseui/spinner'
import { useAuth } from '../../auth'
import StatusTag from '../../components/StatusTag'
import { DotsHorizontalIcon } from '@heroicons/react/solid'
import { MilkdownEditor } from '../../components/MilkdownEditor'
import Timeline from '../../components/Projects/Timeline'
import RequestBody from '../../components/Projects/RequestBody'
import ProjectFileTab from '../../components/Projects/FileTab'
import LogList from '../../components/Projects/LogsList'
import { Input, SIZE as InputSize } from 'baseui/input'
import { Skeleton } from 'baseui/skeleton'

const Header = ({ title, documentId, status }) => {
	const [css, theme] = useStyletron()
	const [isOpen, setIsOpen] = React.useState(false)
	const [changing, setChanging] = React.useState(false)
	const [value, setValue] = React.useState('')
	const [isLoading, setIsLoading] = React.useState(false)
	const navigate = useNavigate()

	const queryClient = useQueryClient()

	const mutation = useMutation(updatePaper, {
		onMutate: () => setIsLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries(['paperDetail', { id: parseInt(documentId) }])
		},
		onSettled: () => {
			setIsLoading(false)
			setChanging(false)
		},
	})

	const deleteMutation = useMutation(deletePaper, {
		onMutate: () => setIsLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries('papers')
			navigate(routes.projects)
		},
		onSettled: () => {
			setIsLoading(false)
			setChanging(false)
		},
	})

	React.useEffect(() => {
		setValue(title)
	}, [title])

	const handleClick = () => setIsOpen(true)

	const handleClose = () => setIsOpen(false)

	function handleSubmit() {
		mutation.mutate({ id: documentId, title: value })
	}

	function handleDelete() {
		deleteMutation.mutate({ id: documentId })
	}

	function handleCancel() {
		setChanging(false)
	}

	return (
		<Block padding="0 10px" marginTop="1rem" marginBottom=".5rem">
			<FlexBlock alignItems="center" justifyContent="space-between" className={changing && 'is-changing'}>
				{changing ? (
					<>
						<Input size={InputSize.compact} overrides={{ Root: { style: { width: '80%' } } }} value={value} onChange={(e) => setValue(e.target.value)} />
						<Block>
							<Button size={ButtonSize.compact} overrides={{ Root: { style: { marginRight: '10px' } } }} isLoading={isLoading} onClick={handleSubmit}>
								저장
							</Button>
							<Button size={ButtonSize.compact} kind={KIND.secondary} onClick={handleCancel}>
								취소
							</Button>
						</Block>
					</>
				) : (
					<>
						<HeadingXSmall width="calc(100% - 100px)" marginBottom="1rem !important" marginLeft="0 !important">
							<FlexBlock alignItems="center">
								{title}
								<LabelMedium display="inline" marginLeft="10px" color="mono800">
									# {documentId}
								</LabelMedium>
							</FlexBlock>
						</HeadingXSmall>

						<Block width="87px">
							<StatefulPopover
								content={({ close }) => (
									<StatefulMenu
										items={[
											{
												label: '수정',
												onClick: () => {
													setChanging(true)
												},
											},
											{
												label: <span className={css({ color: theme.colors.contentNegative })}>삭제</span>,
												onClick: () => {
													handleClick()
												},
											},
										]}
										onItemSelect={({ item }) => {
											item.onClick()
											close()
										}}
									/>
								)}
								placement={PLACEMENT.bottomRight}
								overrides={{
									Body: {
										style: {
											boxShadow: 'unset',
											...theme.borders.border100,
											width: '120px',
										},
									},
									Inner: {
										style: {
											backgroundColor: theme.colors.backgroundPrimary,
										},
									},
								}}
							>
								<BorderedButton size={ButtonSize.compact} kind={KIND.secondary} endEnhancer={<TriangleDown size={16} />}>
									설정
								</BorderedButton>
							</StatefulPopover>
						</Block>
					</>
				)}
				{/*</HeadingXSmall>*/}
			</FlexBlock>
			<FlexBlock alignItems="center">
				<StatusTag status={status} size={SIZE.medium} />
				{/*<Paragraph3 marginLeft='5px'>프로젝트 기간: 2021. 05. 01 ~ 2021. 09. 30</Paragraph3>*/}
			</FlexBlock>
			<Modal
				isOpen={isOpen}
				onClose={handleClose}
				overrides={{
					Root: { style: { zIndex: 20 } },
				}}
			>
				<ModalHeader>의뢰 삭제</ModalHeader>
				<ModalBody>
					<b>[{title}]</b> <p>이 의뢰를 정말 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다.</p>
				</ModalBody>
				<ModalFooter className={css({ marginBottom: '.7rem' })}>
					<WeakButton overrides={{ BaseButton: { style: { width: '100%', ':hover': { backgroundColor: theme.colors.negative500, color: theme.colors.contentInversePrimary } } } }} isLoading={isLoading} onClick={handleDelete}>
						삭제하기
					</WeakButton>
				</ModalFooter>
			</Modal>
		</Block>
	)
}

const TabContentWrapper = (props) => {
	return <Block display="flex" paddingTop="40px" paddingBottom="48px" width="100%" flex="1 0 auto" height="100%" {...props} />
}

// const ProjectDetailForm = (props) => {
//     const formRef = React.useRef();
//
//     return (
//         <React.Fragment>
//             <ProjectRequestForm
//                 ref={formRef}
//                 {...props}
//                 project_categories={[{label: '웹', value: 'web'}, {label: '앱', value: 'app'}]}
//                 domain={[{label: '컨설팅', value: 'consulting'},
//                     {label: '신규 구축', value: 'building'},]}
//                 budget={[{label: '5천만 원 미만', value: 25}]}
//             />
//             <Button overrides={{BaseButton: {style: {float: 'right'}}}}>수정</Button>
//         </React.Fragment>
//     )
// }

const NoteBox = ({ editorKey, paperId, label, editable, onSubmit, hasPerm, ...editorProps }) => {
	const [css, theme] = useStyletron()
	const [editableState, setEditable] = React.useState(editable)

	const queryClient = useQueryClient()

	const mutation = useMutation(deleteNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId }])
			// setIsOpen(false);
		},
	})

	return (
		<FlexBlock
			className={css({
				flexDirection: 'column',
				[theme.mediaQuery.medium]: {
					flexDirection: 'row',
				},
			})}
		>
			<Block
				className={css({
					width: '100%',
					[theme.mediaQuery.medium]: {
						width: '10%',
					},
				})}
			>
				<LabelSmall
					className={css({
						paddingTop: '1rem',
						paddingLeft: '0.5rem',
						paddingBottom: '0.5rem',
						display: 'flex',
						[theme.mediaQuery.medium]: {
							display: 'block',
						},
					})}
				>
					{label}
				</LabelSmall>
			</Block>
			<Block
				className={css({
					width: '100%',
					position: 'relative',
					[theme.mediaQuery.medium]: {
						width: '90%',
					},
				})}
			>
				<MilkdownEditor
					editorKey={editorKey}
					editable={editableState}
					onCancel={() => {
						setEditable(false)
					}}
					onSubmit={(content) => {
						onSubmit(content)
						setEditable(false)
					}}
					{...editorProps}
				/>
				{hasPerm && (
					<Block position="absolute" top="10px" right="15px">
						<StatefulPopover
							content={({ close }) => (
								<StatefulMenu
									items={[
										{
											label: '수정',
											onClick: () => {
												setEditable(true)
											},
										},
										{
											label: <span className={css({ color: theme.colors.contentNegative })}>삭제</span>,
											onClick: () => {
												mutation.mutate(editorKey)
											},
										},
									]}
									onItemSelect={({ item }) => {
										item.onClick()
										close()
									}}
								/>
							)}
							placement={PLACEMENT.bottomRight}
							overrides={{
								Body: {
									style: {
										boxShadow: 'unset',
										...theme.borders.border100,
										width: '120px',
									},
								},
								Inner: {
									style: {
										backgroundColor: theme.colors.backgroundPrimary,
									},
								},
							}}
						>
							<Button kind={KIND.tertiary} shape={SHAPE.circle} size={ButtonSize.mini}>
								<DotsHorizontalIcon width="15px" />
							</Button>
						</StatefulPopover>
					</Block>
				)}
			</Block>
		</FlexBlock>
	)
}

const NoteBoxContainer = ({ paperId }) => {
	paperId = parseInt(paperId)
	const { profile } = useAuth()
	const [css, theme] = useStyletron()
	const { data, isLoading, isError } = useQuery(['noteList', { paperId }], listNotes, { refetchOnMount: false })
	const queryClient = useQueryClient()

	const mutation = useMutation(updateNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId }], { exact: true })
			// setIsOpen(false);
		},
	})

	if (isLoading) {
		return <Skeleton height="125px" width="100%" animation />
	}

	if (isError) {
		return <LabelSmall color="contentNegative">컨텐츠를 가져오는데 실패했습니다</LabelSmall>
	}

	return data.map((row) => (
		<Block key={row.id} margin="0.5rem 0">
			<NoteBox
				editorKey={row.id}
				paperId={paperId}
				label={
					<>
						<Block>{row.author.name}</Block>
						<Block
							className={css({
								fontSize: '10px',
								color: theme.colors.contentSecondary,
								paddingLeft: '.5rem',
								[theme.mediaQuery.medium]: {
									paddingLeft: '0',
								},
							})}
						>
							{sinceFromNow(row.created_datetime)}
						</Block>
					</>
				}
				editable={false}
				initialContent={row.content}
				hasPerm={profile?.id === row.author.id}
				onSubmit={(content) => {
					mutation.mutate({ id: row.id, content })
				}}
			/>
		</Block>
	))
}

export default function ProjectDetail() {
	const [activeKey, setActiveKey] = React.useState('0')
	const [, theme] = useStyletron()
	const modalRef = React.useRef(null)
	const navigate = useNavigate()
	const { enqueue } = useSnackbar()
	const { id } = useParams()
	const queryClient = useQueryClient()

	const { data: paper, isLoading, isError } = useQuery(['paperDetail', { id: parseInt(id) }], detailPaper, { refetchOnMount: false })
	const [loadAttachCount, setLoadAttactCount] = useState(false)

	if (isError) {
		enqueue(
			{
				message: '잘못된 URL 접근입니다.',
				overrides: { Content: { style: { backgroundColor: theme.colors.negative500 } } },
			},
			3000
		)
		navigate(routes.projects)
	}

	const mutation = useMutation(createNote, {
		onSuccess: () => {
			queryClient.invalidateQueries(['noteList', { paperId: parseInt(id) }], { exact: true })
		},
	})

	const handleSubmitNote = (content) => {
		const payload = { paper: id, content: content }
		mutation.mutate(payload)
	}

	const tabContainerOptions = {
		Root: { style: { height: '100%' } },
		Tab: { style: { minWidth: '65px' } },
		TabContent: {
			style: {
				height: '100%',
				display: 'flex',
				flex: '1 0 auto',
				maxWidth: '1200px',
				paddingTop: '0',
				paddingBottom: '0',
			},
		},
	}

	React.useEffect(() => {
		if (paper && paper.attachment_count) {
			if (loadAttachCount) return
			setLoadAttactCount(true)
			const attachmentTab = document.querySelector('[role="tab"][id="2"]')
			const badge = React.createElement('span', { className: 'attachment-badge', children: paper.attachment_count })

			ReactDOM.render(['첨부파일', badge], attachmentTab)
		}
	}, [paper])

	return (
		<>
			<Layout>
				<FlexBlock flexDirection="column" maxWidth="1200px">
					<Header title={paper?.title} documentId={paper?.id} status={paper?.state} />
					<Tabs
						activeKey={activeKey}
						onChange={({ activeKey }) => {
							setActiveKey(activeKey)
						}}
						overrides={tabContainerOptions}
						activateOnFocus
					>
						<Tab title="타임라인">
							{isLoading ? (
								<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
									<Spinner $size="50px" $borderWidth="5px" $color="#000" />
								</FlexBlock>
							) : (
								<TabContentWrapper>
									<FlexBlock height="100%" flexDirection="column" width="765px" maxWidth="100vw" marginRight="1rem" padding="0 0 1rem">
										{/*<LabelSmall paddingLeft='1.25em'>{profile.name}</LabelSmall>*/}
										<Block>
											<MilkdownEditor editorKey="main" id={id} onSubmit={handleSubmitNote} clearAfterSubmit={true} autofocus={true} />
										</Block>
										<Timeline id={id} initial={statusToStep(paper?.state)} />

										<NoteBoxContainer paperId={id} />
									</FlexBlock>
								</TabContentWrapper>
							)}
						</Tab>
						<Tab title="의뢰내용">
							<TabContentWrapper paddingTop={0}>
								<RequestBody paper={paper} />
							</TabContentWrapper>
						</Tab>
						<Tab title="첨부파일">
							<TabContentWrapper flexWrap="wrap">
								{/*<FlexBlock overflow="hidden">*/}
								<ProjectFileTab paperId={id} />
								{/*</FlexBlock>*/}
							</TabContentWrapper>
						</Tab>
						<Tab title="시스템로그">
							<TabContentWrapper>
								<LogList id={id} />
							</TabContentWrapper>
						</Tab>
					</Tabs>
					<ClientDetail ref={modalRef} />
				</FlexBlock>
			</Layout>
		</>
	)
}
