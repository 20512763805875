import { Block } from 'baseui/block'
import Layout from '../../components/Layout'
import { Button, SIZE } from 'baseui/button'
import ContentHeader from '../../components/ContentHeader'
import React from 'react'
import { ChevronRight } from 'baseui/icon'
import { ListItem, ListItemLabel } from 'baseui/list'
import { useQuery } from 'react-query'
import { listEmailTemplates } from '../../core/apis'
import { Link, useNavigate } from 'react-router-dom'
import routes from '../../routes'

export default function EmailTemplateList({ props }) {
	const { isLoading, data = [] } = useQuery('listEmailTemplates', listEmailTemplates)
	const navigate = useNavigate()
	function handleClick() {
		navigate(routes.emailTemplatesCreate)
	}
	return (
		<Layout>
			<ContentHeader
				title={'이메일 템플릿 리스트'}
				actions={
					<Button size={SIZE.compact} onClick={handleClick} $style={{ borderRadius: '3px' }}>
						템플릿 추가 +
					</Button>
				}
			/>
			<Block
				$style={{
					borderRadius: '10px',
					boxShadow: 'rgb(225 225 225 / 40%) 0px 3px 10px',
					overflow: 'hidden',
				}}
			>
				{data.map((row, i) => (
					<Link to={`${routes.emailTemplates}/${row.id}`} key={row.id} style={{ textDecoration: 'none' }}>
						<ListItem
							overrides={{
								Content: {
									style: {
										padding: '0 1.5rem',
										marginLeft: '0',
										borderBottom: i === data.length - 1 ? 'none' : '1px solid rgba(0,0,0,0.08)',
										'@media screen and (max-width: 768px)': {
											padding: '0 15px',
										},
									},
								},
							}}
							endEnhancer={() => <ChevronRight />}
						>
							<ListItemLabel
								overrides={{
									LabelContent: {
										style: {
											fontSize: '16px',
											'@media screen and (max-width: 768px)': {
												fontSize: '14px',
											},
										},
									},
								}}
							>
								{row.name}
							</ListItemLabel>
						</ListItem>
					</Link>
				))}
			</Block>
		</Layout>
	)
}
