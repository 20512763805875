import {useStyletron} from "baseui";
import React from "react";
import {
    defaultValueCtx,
    Editor,
    editorViewCtx,
    editorViewOptionsCtx,
    rootCtx,
    serializerCtx
} from "@milkdown/core";
import {forceUpdate, replaceAll, insert} from "@milkdown/utils";
import {ReactEditor, useEditor} from "@milkdown/react";
import {nordLight} from "@milkdown/theme-nord";
import {commonmark} from "@milkdown/preset-commonmark";
import {history} from "@milkdown/plugin-history";
import {clipboard} from "@milkdown/plugin-clipboard";
import {emoji} from "@milkdown/plugin-emoji";
import FlexBlock from "./FlexBlock";
import {Block} from "baseui/block";
import {Button, KIND, SIZE as ButtonSize} from "baseui/button";
import {PhotographIcon} from "@heroicons/react/outline";
import {useMutation} from "react-query";
import {uploadFile} from "../core/apis";

let editableState = {};

function getEditable(editorKey) {
    return () => typeof editableState[editorKey] === 'undefined' ? true : editableState[editorKey];
}

function useMilkdownEditor (editorKey, initialContent) {
    return useEditor((root) =>
        Editor.make()
            .config((ctx) => {
                ctx.set(rootCtx, root);
                ctx.set(defaultValueCtx, initialContent);
                ctx.set(editorViewOptionsCtx, {editable: getEditable(editorKey)})
            })
            .use(nordLight)
            .use(commonmark)
            .use(history)
            .use(clipboard)
            .use(emoji)
    );
}


export const MilkdownEditor = ({editorKey, id, initialContent, editable, onSubmit, onCancel, clearAfterSubmit, autofocus}) => {
    const [css] = useStyletron();
    const ref = React.useRef();
    const uploadMutation = useMutation(uploadFile, {
        onSuccess: (data) => {
            const instance = getEditorInstance();
            instance.action(insert(`![${data.title}](${data.file})`))
        }
    })
    // const [editableState, setEditable] = React.useState(editable);
    editableState[editorKey] = editable;
    // const [orgValue, setOrgValue] = React.useState(initialContent)


    if (typeof initialContent !== 'string') {
        initialContent = '';
    }
    const editor = useMilkdownEditor(editorKey, initialContent);

    function getEditorInstance () {
        return ref.current?.get();
    }

    function getMarkdown() {
        const editor = ref.current?.get();
        if (!editor) return '';
        return editor.action((ctx) => {
            const editorView = ctx.get(editorViewCtx);
            const serializer = ctx.get(serializerCtx);
            return serializer(editorView.state.doc);
        })
    }

    function clearContent() {
        const editor = ref.current?.get();
        if (!editor) return '';
        editor.action(replaceAll("", true));
    }

    function handleCancel () {
        const editor = ref.current?.get();
        if (!editor) return '';
        editor.action(replaceAll(initialContent, true));

    }

    function focus (editor) {
        if (editor) {
            // setEditable(true)
            if (getEditable(editorKey)()) {
                const domm = ref.current?.dom().querySelector('.milkdown .editor');
                domm?.focus();
            }
        }
    }

    React.useEffect(() => {
        if (autofocus) {
            focus(getEditorInstance());
        }
    }, [ref.current])

    React.useEffect(() => {
        const editor = getEditorInstance();
        if (editor) {
            // setEditable(true)
            editor.action(forceUpdate());
            focus(editor);
        }
    }, [editable])

    return (
        <>
            <ReactEditor editor={editor} ref={ref} tabIndex='-1'/>
            {getEditable(editorKey)() && (
                <FlexBlock justifyContent="space-between" marginTop='.5rem'>
                    <Block marginTop='0.25rem'>
                        <input type="file" accept={"image/*"} className={css({display: 'none'})} id="blah"
                               onChange={(e) => {
                                   const file = e.target.files[0];
                                   const formData = new FormData();

                                   formData.append('file', file);
                                   formData.append('paper', id);
                                   formData.append('is_note_image', true);
                                   uploadMutation.mutate(formData)
                               }}/>
                        <Button kind={KIND.tertiary} size={ButtonSize.mini}
                                onClick={() => {
                                    const input = document.querySelector('#blah');
                                    input.click();
                                }}

                        >
                            <PhotographIcon className={css({width: '20px'})}/>
                        </Button>
                    </Block>
                    <Block>
                        {onCancel && (
                            <Button
                                size={ButtonSize.compact}
                                kind={KIND.secondary}
                                onClick={() => {
                                    handleCancel();
                                    onCancel();
                                }}
                                overrides={{ Root: { style: {marginRight: '.5rem'}} }}
                            >취소</Button>
                        )}
                        <Button
                            size={ButtonSize.compact}
                            onClick={() => {
                                onSubmit && onSubmit(getMarkdown());
                                if (clearAfterSubmit) {
                                    clearContent();
                                }
                            }}
                            $style={{
                                borderRadius:'3px'
                            }}
                        >작성</Button>
                    </Block>
                </FlexBlock>
            )}

        </>

    )
}