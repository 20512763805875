import { Button } from 'baseui/button'
import { styled } from 'baseui'
import { FormControl } from 'baseui/form-control'
import { Input } from 'baseui/input'
import { LabelMedium } from 'baseui/typography'
import { useEffect, useState } from 'react'
import { useAuth } from '../../auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { getQueryVariable } from '../../core/utils'
import DeleteAlt from 'baseui/icon/delete-alt'
import { useSnackbar } from 'baseui/snackbar'
import routes from '../../routes'

const Wrapper = styled('div', ({ $theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
}))

const Container = styled('div', ({ $theme }) => ({
	width: '300px',
}))

export function SignIn() {
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const location = useLocation()
	const navigate = useNavigate()
	const auth = useAuth()
	const next = location.state?.from?.pathname
	const { enqueue } = useSnackbar()
	const [hasError, setError] = useState(false)

	useEffect(() => {
		if (!window.location.search && next) {
			const realNext = next === '/' ? routes.projects : next
			window.location.search = `?next=${realNext}`
		}
		if (auth.isAuthenticated) {
			auth.verify().then((result) => {
				if (result) {
					redirect()
				} else {
					auth.logout()
				}
			})
		}
	}, []) // eslint-disable-line

	function redirect() {
		navigate(next || getQueryVariable('next') || routes.projects)
	}

	function handleSubmit(e) {
		e.preventDefault()
		auth.login({ username, password })
			.then((result) => {
				if (result) redirect()
			})
			.catch((e) => {
				console.dir(e)
				setError(true)
				if (e.response?.status === 401) {
					enqueue({
						message: '아이디 혹은 비밀번호가 일치하지 않습니다.',
						startEnhancer: ({ size }) => <DeleteAlt size={size} />,
					})
				} else {
					enqueue({
						message: '서버 에러',
						startEnhancer: ({ size }) => <DeleteAlt size={size} />,
					})
				}
			})
	}

	return (
		<Wrapper>
			<Container>
				<form onSubmit={handleSubmit} autoComplete="on">
					<LabelMedium overrides={{ Block: { style: { marginBottom: '2.25rem' } } }}>
						<b>그룹웨어 계정</b>으로 로그인할 수 있습니다.
					</LabelMedium>
					<FormControl label="아이디">
						<Input value={username} autofocus="true" autoCapitalize="off" onChange={(e) => setUsername(e.currentTarget.value)} error={hasError} />
					</FormControl>
					<FormControl label="비밀번호">
						<Input value={password} autofocus="true" autoCapitalize="off" onChange={(e) => setPassword(e.currentTarget.value)} type="password" error={hasError} />
					</FormControl>
					<Button overrides={{ BaseButton: { style: { width: '100%', marginTop: '1.25rem' } } }} isLoading={auth.isLoggingIn} disabled={auth.isLoggingIn}>
						로그인
					</Button>
				</form>
			</Container>
		</Wrapper>
	)
}
