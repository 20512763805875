import React from "react";

import {Button, SIZE} from "baseui/button";
import ProjectForm from "../../components/Projects/Form";
import ContentHeader from "../../components/ContentHeader";
import RequestList from "../../components/Projects/RequestList";
import Layout from "../../components/Layout";



export default function Projects () {
    const formModalRef = React.useRef();

    function openFormModal () {
        const ref = formModalRef.current;
        ref?.reset();
        ref?.setIsOpen(true);
    }

    return (
        <Layout>
            <ContentHeader
                title={"프로젝트 리스트"}
                actions={<Button size={SIZE.compact} onClick={openFormModal} $style={{borderRadius:'3px'}} >프로젝트 추가 +</Button>}
            />
            <RequestList />
            <ProjectForm ref={formModalRef} />
        </Layout>
    )
}