import React from 'react';
import {Card as BaseCard, StyledAction} from "baseui/card";
import FlexBlock from "../../components/FlexBlock";
import {useStyletron} from "baseui";
import {Block} from "baseui/block";
import {Button, SHAPE, SIZE} from "baseui/button";
import ClientDetail from "../../components/Clients/Detail";
import ContentHeader from "../../components/ContentHeader";
import Layout from "../../components/Layout";


const Card = (props) => {
    const [css, theme] = useStyletron();  // eslint-disable-line
    return (
        <BaseCard
            overrides={{
                Root: {style: {width: '300px', marginRight: '1rem', marginBottom: '1rem'}},
                Title: {style: {...theme.typography.HeadingXSmall}},
                Action: {style: {marginTop: '10px'}}
            }}
            {...props}
        />
    )
}

const CardBody = (props) => {
    const [css] = useStyletron();
    return (
        <Block
            className={css({fontSize: '13px'})}
            {...props}
        />
    )
}

const CardAction = (props) => {
    const [css] = useStyletron();
    return (
        <StyledAction className={css({marginTop: '20px', textAlign:'right'})} >
            <Button
                size={SIZE.compact}
                shape={SHAPE.pill}
                overrides={{
                    BaseButton: { style: { width: '100%' } }
                }}
                // onClick={}
                {...props}
            />
        </StyledAction>
    )
}


export default function Clients () {
    const [css] = useStyletron();
    const modalRef = React.useRef(null);

    function openClientDetailModal () {
        const {current} = modalRef;
        current.reset();
        current.setIsOpen(true);
    }
    return (
        <Layout>
            <ContentHeader title='클라이언트 리스트'>
                <Button
                    size={SIZE.compact}
                    className={css({float: 'right'})}
                    // onClick={openFormModal}
                >클라이언트 추가 +</Button>
            </ContentHeader>

            <FlexBlock flexWrap="wrap">

                <Card title="엔씨소프트" >
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <Card title="KT">
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <Card title="크래프톤">
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <Card title="SK">
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <Card title="APPLE">
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <Card title="나이스홀딩스">
                    <CardBody>
                        VARIETY PLAYNC. 게임 외에도 다양한 플레이엔씨 서비스를 만나보세요. ...
                    </CardBody>
                    <CardAction onClick={openClientDetailModal}>자세히 보기</CardAction>
                </Card>
                <ClientDetail ref={modalRef} />
            </FlexBlock>
        </Layout>
    )
}