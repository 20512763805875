import { FileUploader } from 'baseui/file-uploader'
import React from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { listFiles, uploadFile } from '../../core/apis'
import FlexBlock from '../FlexBlock'
import { Spinner } from 'baseui/spinner'
import { Card, StyledBody } from 'baseui/card'
import { useStyletron } from 'styletron-react'

export default function ProjectFileTab({ paperId }) {
	const [isUploading, setIsUploading] = React.useState(false)
	const timeoutId = React.useRef(null)
	const [css] = useStyletron()
	const { isLoading, data, isSuccess } = useQuery(['files', { paper: paperId }], listFiles)

	const queryClient = useQueryClient()

	const mutation = useMutation(uploadFile, {
		onSuccess: () => {
			reset()
			queryClient.invalidateQueries(['files', { paper: paperId }]).then(() => {})
		},
	})

	function reset() {
		setIsUploading(false)
		clearTimeout(timeoutId.current)
	}
	function startProgress() {
		setIsUploading(true)
	}

	function handleDrop(acceptedFiles) {
		const file = acceptedFiles[0]
		const formData = new FormData()
		formData.append('file', file)
		formData.append('paper', paperId)
		formData.append('is_note_image', false)

		mutation.mutate(formData)
	}

	if (isLoading) {
		return (
			<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
				<Spinner $size="50px" $borderWidth="5px" $color="#000" />
			</FlexBlock>
		)
	}

	return (
		<>
			<FileUploader
				onCancel={reset}
				onDrop={(acceptedFiles, rejectedFiles) => {
					// handle file upload...
					startProgress()
					handleDrop(acceptedFiles)
				}}
				progressMessage={isUploading ? `Uploading...` : ''}
				multiple={false}
				overrides={{ Root: { style: { width: '100%', marginBottom: '10px' } } }}
			/>
			<FlexBlock flexWrap="wrap" className={css({ columnGap: '5px', rowGap: '5px' })}>
				{isSuccess &&
					data.results.map((row) => (
						<Card
							key={row.id}
							overrides={{ Root: { style: { width: '225px', height: '132px', overflow: 'hidden', cursor: 'pointer' } } }}
							onClick={() => {
								window.open(row.file, '_blank')
							}}
						>
							<StyledBody className={css({ overflow: 'hidden', lineHeight: 1.2 })}>
								<img
									src={row.file}
									width="100%"
									height="auto"
									onError={(e) => {
										e.target.parentElement.innerHTML = `<span style="font-size: 12px">${row.title}</span>`
									}}
								/>
							</StyledBody>
						</Card>
					))}
			</FlexBlock>
		</>
	)
}
