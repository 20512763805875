import React, { useEffect, useRef } from 'react'
import { useStyletron } from 'baseui'
import { useLocation, useNavigate } from 'react-router-dom'
import { BookOpenIcon, DocumentReportIcon, LogoutIcon, ChartBarIcon } from '@heroicons/react/outline'
import Logo from '../Logo'
import SidebarItem from './SidebarItem'
import BaseSidebar from 'react-sidebar'
import { LabelXSmall } from 'baseui/typography'
import Settings from './Settings'
import routes from '../../routes'
import { useAuth } from '../../auth'

const SidebarItemList = (props) => {
	const { loc } = props
	const auth = useAuth()
	const [, theme] = useStyletron()
	const settingRef = useRef()
	const navigate = useNavigate()

	function handleClickSetting() {
		settingRef.current.setIsOpen(true)
	}

	return (
		<React.Fragment>
			<SidebarItem
				children={<Logo />}
				to={routes.projects}
				overrides={{
					Root: { style: { marginBottom: '3rem', padding: '0 2rem 0', ':hover': {} } },
					Item: { style: { marginLeft: '0rem' } },
				}}
			/>
			<SidebarItem children={<LabelXSmall color={theme.colors.contentSecondary}>Menu</LabelXSmall>} overrides={{ Item: { style: { marginLeft: '0' } } }} />
			{/*<SidebarItem*/}
			{/*    Icon={ViewGridIcon}*/}
			{/*    active={loc.pathname === routes.dashboard}*/}
			{/*    to={routes.dashboard}*/}
			{/*    children="대시보드"*/}
			{/*/>*/}
			<SidebarItem Icon={DocumentReportIcon} active={loc.pathname === routes.projects || loc.pathname.startsWith('/papers')} to={routes.projects} children="프로젝트 관리" />
			<SidebarItem Icon={BookOpenIcon} active={loc.pathname.startsWith(routes.emailTemplates)} to={routes.emailTemplates} children="템플릿 관리" />
			<SidebarItem Icon={ChartBarIcon} active={loc.pathname.startsWith(routes.dashboard)} to={routes.dashboard} children="대시보드" />
			{/*<SidebarItem*/}
			{/*    Icon={OfficeBuildingIcon}*/}
			{/*    active={loc.pathname.startsWith(routes.clients)}*/}
			{/*    to={routes.clients}*/}
			{/*    children="클라이언트 관리"*/}
			{/*    disabled*/}
			{/*/>*/}
			{/*<SidebarItem*/}
			{/*    Icon={UserIcon}*/}
			{/*    active={loc.pathname.startsWith(routes.accounts)}*/}
			{/*    to={routes.accounts}*/}
			{/*    children="계정 관리"*/}
			{/*    disabled*/}
			{/*/>*/}
			<SidebarItem
				children={<LabelXSmall color={theme.colors.contentSecondary}>Settings</LabelXSmall>}
				overrides={{
					Root: { style: { marginTop: 'auto' } },
					Item: { style: { marginLeft: '0' } },
				}}
			/>
			{/*<SidebarItem*/}
			{/*    Icon={<CogIcon width={16} />}*/}
			{/*    children="설정"*/}
			{/*    overrides={{*/}
			{/*        Root: {style: {cursor: 'pointer'}},*/}
			{/*        Item: {style: {...theme.typography.font200}}*/}
			{/*    }}*/}
			{/*    disabled*/}
			{/*    onClick={handleClickSetting}*/}
			{/*/>*/}
			<SidebarItem
				Icon={<LogoutIcon width={16} />}
				children="로그아웃"
				overrides={{
					Root: { style: { marginBottom: '20%', cursor: 'pointer' } },
					Item: { style: { ...theme.typography.font200 } },
				}}
				onClick={() => {
					auth.logout()
					navigate(routes.projects)
				}}
			/>
			<Settings ref={settingRef} />
		</React.Fragment>
	)
}

export default function Sidebar(props) {
	const { sidebarOpen, setSidebarOpen, sidebarDocked, children, ...restProps } = props
	const [css, theme] = useStyletron() // eslint-disable-line
	const loc = useLocation()

	return (
		<BaseSidebar
			defaultSidebarWidth={294}
			sidebar={<SidebarItemList loc={loc} />}
			open={sidebarOpen}
			onSetOpen={setSidebarOpen}
			docked={sidebarDocked}
			shadow={false}
			styles={{
				sidebar: {
					width: '294px',
					backgroundColor: theme.colors.backgroundPrimary,
					padding: '1rem 0',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
			{...restProps}
		>
			{children}
		</BaseSidebar>
	)
}
