import FlexBlock from './FlexBlock'
import React from 'react'
import { HeadingXSmall } from 'baseui/typography'

export default function ContentHeader({ title, actions, ...resProps }) {
	return (
		<FlexBlock marginBottom=".75rem" alignItems="center" {...resProps}>
			{/*<Block flex='1'>*/}
			<HeadingXSmall margin="0">{title}</HeadingXSmall>
			<FlexBlock marginLeft="auto">{actions}</FlexBlock>
			{/*</Block>*/}
		</FlexBlock>
	)
}
