import FlexBlock from '../FlexBlock'
import { HeadingXSmall, LabelSmall } from 'baseui/typography'
import { Block } from 'baseui/block'
import { useStyletron } from 'baseui'
import Link from '../Link'
import { Alert } from 'baseui/icon'
import { Button, KIND, SIZE } from 'baseui/button'
import { useRef } from 'react'
import { XIcon } from '@heroicons/react/outline'

function EmptyState() {
	return (
		<FlexBlock alignItems="center" justifyContent="center" padding="2rem 0">
			<LabelSmall>새로운 알림이 없습니다.</LabelSmall>
		</FlexBlock>
	)
}

function NotificationItem(props) {
	const { item, close, ...rest } = props
	const [css, theme] = useStyletron()

	const dateTextCustom = (date) => {
		const textSplit = date.split('시각: ')
		return `${textSplit[0].split('(')[1]}시각 : ${textSplit[1].split(')')[0].split('.')[0].split('-').join('.')}`
	}

	const splitText = (text) => {
		const textSplit = text.split('습니다.')
		if (textSplit.length >= 2) {
			return `${textSplit[0]}습니다.\n${dateTextCustom(textSplit[1])}`
		}
	}

	return (
		<FlexBlock alignItems="center" className="noti-list" {...rest}>
			<span
				className={css({
					display: item.is_read ? 'none' : 'block',
					minWidth: '6px',
					height: '6px',
					marginRight: '1rem',
					backgroundColor: 'red',
					borderRadius: '50%',
				})}
			/>
			<Block className={css({ ...theme.typography.font250 })}>
				<Link to={item.url}
					style={{ whiteSpace: 'pre-wrap', lineHeight: 1.2 }}
					onClick={close}
					>
					{splitText(item.content)}
				</Link>
			</Block>
		</FlexBlock>
	)
}

function NotificationContainer({ items, close }) {
	const contentExists = items.length > 0
	return contentExists ? items.map((notification, index) => <NotificationItem item={notification} key={index} close={close} />) : <EmptyState />
}

export default function NotificationList({ close, content, isError, hasNextPage, isFetchingNextPage, fetchNextPage, isIdle }) {
	const [css, theme] = useStyletron()
	const ref = useRef()

	function handleClick() {
		fetchNextPage()
	}

	return (
		<FlexBlock flexDirection="column" width="480px" maxWidth="100vw">
			<FlexBlock
				className={css({
					height: '50px',
					padding: '0 1.5rem',
					boxSizing: 'border-box',
					borderBottom: `1px solid ${theme.colors.borderAlt}`,
					alignItems: 'center',
					justifyContent:'space-between'
				})}
			>
				<HeadingXSmall marginTop="0" marginBottom="0" marginLeft="0 !important" font="font350">
					알림
				</HeadingXSmall>
				<Button onClick={() => close()} className={css({backgroundColor:'transparent !important', transform:'translate(16px, 0)'})}>
					<XIcon className={css({ stroke: theme.colors.contentPrimary, width: '24px' })}/>
				</Button>
			</FlexBlock>
			<FlexBlock flexDirection="column" maxHeight="642px" overflow="auto" ref={ref}>
				{isError ? (
					<FlexBlock alignItems="center" justifyContent="center" padding="2rem 0">
						<Alert size={18} color={theme.colors.notificationNegativeText} />
						<LabelSmall>알림을 가져올 수 없습니다 [네트워크 실패]</LabelSmall>
					</FlexBlock>
				) : (
					<NotificationContainer items={content} close={close} />
				)}
				{hasNextPage && (
					<Button kind={KIND.secondary} size={SIZE.compact} isLoading={isFetchingNextPage} onClick={handleClick}>
						Read More
					</Button>
				)}
			</FlexBlock>
		</FlexBlock>
	)
}
