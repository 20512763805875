import { useNavigate } from 'react-router-dom'
import * as defaultComponents from '../SidebarItem'
import { getComponents } from '../../core/utils'

export default function SidebarItem(props) {
	const { Icon, onClick, to, overrides, active, disabled, children } = props
	const navigate = useNavigate()
	const {
		Root: { component: Root, props: rootProps },
		IconContainer: { component: IconContainer, props: iconProps },
		Item: { component: Item, props: itemProps },
	} = getComponents(defaultComponents, overrides || {})

	const internalOnClick = (...args) => {
		args.to = to
		if (disabled) return
		if (onClick) {
			onClick(...args)
		} else if (to) {
			navigate(to)
		}
	}

	return (
		<Root {...rootProps} to={to} active={active} disabled={disabled} onClick={internalOnClick}>
			{Icon && <IconContainer {...iconProps}>{typeof Icon === 'object' ? Icon : <Icon style={{ width: '20px' }} />}</IconContainer>}
			<Item {...itemProps}>{children}</Item>
		</Root>
	)
}
