import React, { useEffect, useRef, useState } from 'react'
import { styled, withStyle } from 'baseui'
import { countAnimation, countFadeAnimation, numberWithCommas } from '../../core/utils'

export const CountBox = ({ title, count, style, child, delay }) => {
	const StyledContainer = withStyle(Container, {
		...style,
	})

	const containerRef = useRef(null)

	useEffect(() => {
		countFadeAnimation(containerRef.current, delay)
	}, [count, delay])

	return (
		<StyledContainer ref={containerRef}>
			<Title>{title}</Title>
			<Count>
				<Text className="count">{numberWithCommas(count)}</Text>
				{child && <Unit>{child}</Unit>}
			</Count>
		</StyledContainer>
	)
}

const Container = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	width: '20%',
	padding: '2rem',
	justifyContent: 'space-between',
	borderRadius: '20px',
	wordBreak: 'keep-all',
	backgroundColor: '#fff',
	transition: 'all 450ms cubic-bezier(0.25, 1, 0.5, 1)',
	// boxShadow: '1em 1em 2em 3px rgb(0 0 0 / 5%)',
	boxShadow: 'rgb(225 225 225 / 40%) 0px 3px 10px',
	transform: 'scale(1)',
	userSelect: 'none',
	':active': {
		transform: 'scale(0.97)',
		boxShadow: 'none',
		transition: 'all 400ms cubic-bezier(0.25, 1, 0.5, 1)',
	},
	'@media screen and (max-width:768px)': {
		width: '100%',
		height: 'auto',
		boxSizing: 'border-box',
		padding: '1.5rem',
	},
})

const Title = styled('p', {
	margin: 0,
	letterSpacing: '-0.02em',
	fontSize: '16px',
	fontWeight: 500,
	'@media screen and (max-width:768px)': {
		fontSize: '14px',
	},
})

const Count = styled('p', {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	alignItems: 'center',
	justifyContent: 'space-between',
	margin: '2rem 0 0',
	letterSpacing: '-0.02em',
	'@media screen and (max-width:768px)': {
		flexDirection:'column',
		alignItems:'flex-start'
	},
})
const Text = styled('span', {
	paddingRight: '0.5rem',
	fontSize: '24px',
	fontWeight: 700,
	opacity: 0,
	visibility: 'hidden',
	'@media screen and (max-width:768px)': {
		paddingRight:0
	},
})
const Unit = styled('span', {
	padding: '0.5rem 0',
	fontSize: '14px',
	fontWeight: 300,
	'@media screen and (max-width:768px)': {
		fontSize: '12px',
		alignSelf:'flex-end',
		padding:'1rem 0 0'
	},
})
