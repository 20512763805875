import React from 'react'
import { useStyletron } from 'baseui'
import { Block } from 'baseui/block'
import { SIZE, ROLE, Modal, ModalHeader, ModalBody, ModalFooter, ModalButton } from 'baseui/modal'
import { KIND as ButtonKind } from 'baseui/button'
import { Input } from 'baseui/input'
import { Textarea } from 'baseui/textarea'
import { Select } from 'baseui/select'
import { FormControl } from 'baseui/form-control'

import FlexBlock from '../FlexBlock'
import { useQuery, useMutation, useQueryClient } from 'react-query'

import { listEmailTemplates, sendEmail } from '../../core/apis'
import { MailIcon } from '@heroicons/react/outline'
import { useSnackbar } from 'baseui/snackbar'

export default function EmailTemplate({ isOpen, onClose, onSubmit, paper }) {
	const { isLoading, data = [] } = useQuery('templates', listEmailTemplates)
	const [css, theme] = useStyletron()
	const [value, setValue] = React.useState([])
	const [subject, setSubject] = React.useState('')
	const [body, setBody] = React.useState('')
	const [sendWaiting, setSendWaiting] = React.useState(false)
	const { enqueue } = useSnackbar()

	const queryClient = useQueryClient()

	const _onSubmit = () => {
		onSubmit()
		clear()
	}
	const _onClose = () => {
		onClose()
		clear()
	}

	const mutation = useMutation(sendEmail, {
		onMutate: () => setSendWaiting(true),
		onSuccess: () => {
			_onSubmit()
			queryClient.invalidateQueries('templates').then()
		},
		onError: () => {
			enqueue({
				message: '이메일 전송에 실패하였습니다',
				overrides: { Content: { style: { backgroundColor: theme.colors.negative500 } } },
			})
		},
		onSettled: () => {
			setSendWaiting(false)
		},
	})

	function serializeData() {
		return {
			id: value[0].isCreatable ? null : value[0].id,
			name: value[0].label,
			subject: subject,
			body: body,
			paper_id: paper,
		}
	}

	function findTemplateFrom(id) {
		return data.find((row) => row.id === parseInt(id))
	}

	function clearInputs() {
		setSubject('')
		setBody('')
	}

	function clear() {
		clearInputs()
		setValue([])
	}

	function handleSelectChange(params) {
		const { type, option } = params
		switch (type) {
			case 'select':
				const { id, isCreatable } = option
				if (!isCreatable) {
					const template = findTemplateFrom(id)
					setSubject(template.subject)
					setBody(template.body)
				} else {
					clearInputs()
				}
				setValue([option])
				break
			default:
				setValue([])
				break
		}
	}

	return (
		<Modal closeable={false} isOpen={isOpen} onClose={_onClose} animate autoFocus size={SIZE.default} role={ROLE.dialog} overrides={{ Root: { style: { zIndex: 3 } } }}>
			<ModalHeader>{'담당자에게 이메일 전송'}</ModalHeader>

			<ModalBody>
				<FlexBlock flexDirection="column" className={css({ rowGap: theme.sizing.scale600 })}>
					<Select backspaceRemoves={false} creatable isLoading={isLoading} options={data} value={value} name="name" placeholder="템플릿 선택" onChange={handleSelectChange} />
					<Block>
						<FormControl label="이메일 제목">
							<Input placeholder="제목을 입력해주세요" id="subject" name="subject" required={true} value={subject} disabled={!value.length} onChange={(e) => setSubject(e.target.value)} />
						</FormControl>
					</Block>

					<Block>
						<FormControl
							label="이메일 본문"
							caption={() => (
								<ul>
									<li>아래 내용이 치환되어 메일이 전송됩니다</li>
									<li>{'{클라이언트}: 의뢰한 회사 이름'}</li>
									<li>{'{담당자}: 의뢰한 회사의 담당자 이름'}</li>
									<li>{'{담당자:연락처}: 담당자 전화번호'}</li>
									<li>{'{담당자:이메일}: 담당자 이메일주소'}</li>
									<li>{'{의뢰명}: 의뢰한 프로젝트의 제목'}</li>
								</ul>
							)}
						>
							<Textarea
								placeholder="내용을 입력해주세요."
								name="body"
								value={body}
								disabled={!value.length}
								onChange={(e) => setBody(e.target.value)}
								overrides={{
									Input: {
										style: {
											minHeight: '40vh',
											resize: 'vertical',
										},
									},
								}}
							/>
						</FormControl>
					</Block>
				</FlexBlock>
			</ModalBody>

			<ModalFooter className={css({ display: 'flex', alignItems: 'center' })}>
				<ModalButton kind={ButtonKind.tertiary} onClick={_onClose} overrides={{ BaseButton: { style: { float: 'left' } } }}>
					취소
				</ModalButton>
				<ModalButton kind={ButtonKind.tertiary} onClick={_onSubmit} overrides={{ BaseButton: { style: { backgroundColor: 'transparent', color: theme.colors.accent400, marginLeft: 'auto' } } }}>
					Skip
				</ModalButton>
				<ModalButton
					startEnhancer={<MailIcon width="20px" />}
					isLoading={sendWaiting}
					onClick={() => {
						mutation.mutate(serializeData())
					}}
				>
					발송
				</ModalButton>
			</ModalFooter>
		</Modal>
	)
}
