import { useStyletron } from 'baseui'
import { ProgressSteps, Step } from 'baseui/progress-steps'
import { Button, KIND, SIZE } from 'baseui/button'
import { Select, SIZE as SelectSize } from 'baseui/select'
import React from 'react'
import { statusToStep } from '../../core/utils'
import { useMutation, useQueryClient } from 'react-query'
import { updatePaperStatus } from '../../core/apis'
import { ButtonGroup } from 'baseui/button-group'
import Confirm from '../Confirm'
import EmailTemplate from './EmailTemplate'
import { StateChoices } from '../../core/options';

export default function Timeline({ id, initial }) {
	id = parseInt(id)
	const [step, setStep] = React.useState(initial || 0)
	const [selectValue, setSelectValue] = React.useState()
	const [selectError, setSelectError] = React.useState(false)
	const [modalOpen, setModalOpen] = React.useState([false, false])
	const [, theme] = useStyletron()

	const [overrides, setOverrides] = React.useState({
		Root: {
			style: {
				display: 'flex',
				flexDirection: 'row',
				flexWrap: 'wrap',
				width: '100%',
				minWidth: '100%',
				margin: '2rem 0',
				padding: 0,
				textAlign: 'center',
				wordBreak: 'keep-all',
			},
		},
		StepRoot: {
			style: {
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				marginBottom: 'auto',
				maxWidth: '25%',
				minWidth: '25%',
				zIndex: 0,
			},
		},
		Content: {
			style: {
				display: 'flex',
				flexDirection: 'column',
				marginLeft: 0,
				flexWrap: 'wrap',
			},
		},
		Icon: {
			style: {
				transform: 'translateY(2px)',
			},
		},
		IconContainer: {
			style: {
				width: '16px',
				height: '16px',
				boxSizing: 'border-box',
				marginTop: '24px',
				zIndex: 1,
			},
		},
		Title: {
			style: {
				height: '88px',
				letterSpacing: '-0.02em',
				fontSize: '14px',
				'@media screen and (max-width: 768px)': {
					fontSize: '14px',
				},
			},
		},
		Tail: {
			style: {
				left: '0',
				right: '0',
				width: '100%',
				height: '2px',
				marginTop: '33px',
				marginLeft: 'auto',
				marginRight: 'auto',
				transform: 'translateX(50%)',
			},
		},
	})

	const queryClient = useQueryClient()
	const updateStatusMutation = useMutation(updatePaperStatus)

	function _handleUpdateStatus(state) {
		updateStatusMutation.mutate(
			{ state, id },
			{
				onSuccess: () => {
					setStep(statusToStep(state))
					queryClient.invalidateQueries(['paperDetail', { id }], { exact: true }).then(() => {
						console.log('invalidated')
					})
					queryClient.invalidateQueries('papers').then(() => {
						console.log('invalidated')
					})
				},
			}
		)
	}

	function handleProceed() {
		_handleUpdateStatus(StateChoices.ALIGNING)
	}

	function handleDecline() {
		_handleUpdateStatus(StateChoices.REJECTED)
	}

	function handleStartProject() {
		_handleUpdateStatus(StateChoices.IN_PROGRESS)
	}

	function handleComplete() {
		_handleUpdateStatus(StateChoices.COMPLETED)
	}

	function handleUpdateStatus() {
		if (selectValue) {
			_handleUpdateStatus(selectValue[0].id)
		}
		setModalOpen([false, false])
		setSelectValue(null)
	}

	function closeModals() {
		setModalOpen([false, false, false])
	}

	return (
		<>
			<ProgressSteps current={step} overrides={overrides}>
				<Step title="의뢰 접수">
					<Button size="compact" kind={KIND.secondary} onClick={() => setModalOpen([false, false, true])} overrides={{ Root: { style: { background: theme.colors.primary400 } } }} children="거절" />
					<Button size="compact" onClick={handleProceed} children="진행" />
				</Step>
				<Step title="협의 진행">
					<Button size="compact" kind={KIND.secondary} onClick={() => setModalOpen([false, false, true])} overrides={{ Root: { style: { background: theme.colors.primary400 } } }} children="거절" />
					<Button size="compact" onClick={handleStartProject} children="진행" />
				</Step>
				<Step title="착수">
					<Button size="compact" kind={KIND.secondary} onClick={handleProceed} overrides={{ Root: { style: { background: theme.colors.primary400 } } }} children="이전" />
					<Button size="compact" onClick={() => setModalOpen([false, true, false])} children="종료" />
				</Step>
				<Step title="종료">
					<ButtonGroup size={SIZE.compact} selected={1}>
						<Select
							error={selectError}
							clearable={false}
							deleteRemoves={false}
							size={SelectSize.compact}
							options={[
								{ label: '접수대기', id: 'new' },
								{ label: '협의진행', id: 'aligning' },
								{ label: '프로젝트 진행', id: 'in_progress' },
							]}
							overrides={{ Root: { style: { display: 'inline-flex', width: '128px' } } }}
							value={selectValue}
							placeholder="상태 선택"
							onChange={(params) => {
								setSelectValue(params.value)
								setSelectError(false)
							}}
						/>
						<Button kind={KIND.primary} size={SIZE.compact} onClick={() => setModalOpen([true, false, false])} children="변경" />
					</ButtonGroup>
				</Step>
			</ProgressSteps>
			<Confirm
				isOpen={modalOpen[0]}
				onClose={closeModals}
				onConfirm={() => {
					if (!selectValue) {
						setSelectError(true)
					} else {
						handleUpdateStatus()
						closeModals()
					}
				}}
				body="프로젝트의 상태 변경을 계속 진행할까요?"
			/>
			<Confirm
				isOpen={modalOpen[1]}
				onClose={closeModals}
				onConfirm={() => {
					handleComplete()
					closeModals()
				}}
				body="프로젝트를 종료할까요?"
			/>
			<EmailTemplate
				onClose={closeModals}
				onSubmit={() => {
					handleDecline()
					closeModals()
				}}
				isOpen={modalOpen[2]}
				paper={parseInt(id)}
			/>
		</>
	)
}
