import {Block} from 'baseui/block'
import Layout from '../../components/Layout'
import {Button, KIND} from 'baseui/button'
import ContentHeader from '../../components/ContentHeader'
import React from 'react'
import {Check, DeleteAlt} from 'baseui/icon'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {
	createEmailTemplate,
	deleteEmailTemplate,
	detailEmailTemplate,
	listEmailTemplates,
	updateEmailTemplate
} from '../../core/apis'
import {useNavigate, useParams} from 'react-router-dom'
import routes from '../../routes'
import {Spinner} from 'baseui/spinner'
import FlexBlock from '../../components/FlexBlock'
import {Input} from 'baseui/input'
import {FormControl} from 'baseui/form-control'
import {Textarea} from 'baseui/textarea'
import {Cell, Grid} from 'baseui/layout-grid'
import {DURATION, useSnackbar} from 'baseui/snackbar'
import Confirm from '../../components/Confirm'

const buttonProps = {
	overrides: {
		BaseButton: {
			style: ({ $theme }) => ({
				width: '100%',
			}),
		},
	},
}

const defaultFormData = {
	name: '',
	subject: '',
	body: '',
}

function EmailTemplateForm({ id, initialData }) {
	const [deleteLoading, setDeleteLoading] = React.useState(false)
	const [submitLoading, setSubmitLoading] = React.useState(false)
	const [modalOpen, setModalOpen] = React.useState(false)
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { enqueue } = useSnackbar()
	const isNew = !id
	const mutationFunc = isNew ? createEmailTemplate : updateEmailTemplate
	const mutation = useMutation({
		mutationFn: mutationFunc,
		onMutate: () => setSubmitLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries('listEmailTemplates').then(() => {
				enqueue(
					{
						message: '변경사항이 저장되었습니다',
						startEnhancer: ({ size }) => <Check size={size} />,
					},
					DURATION.short
				)
				navigate(routes.emailTemplates)
			})
		},
		onError: () => setSubmitLoading(false),
	})

	const deleteMutation = useMutation({
		mutationFn: deleteEmailTemplate,
		onMutate: () => setDeleteLoading(true),
		onSuccess: () => {
			queryClient.invalidateQueries('listEmailTemplates').then(() => {
				let message = ''
				const name = formData.name?.slice(0, 5)
				if (formData.name?.length > 5) {
					message = name + '...가 삭제되었습니다.'
				} else {
					message = name + '가 삭제되었습니다.'
				}
				enqueue(
					{
						message,
						overrides: { Content: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.negative500 }) } },
						startEnhancer: ({ size }) => <DeleteAlt size={size} />,
					},
					DURATION.short
				)
				navigate(routes.emailTemplates)
			})
		},
		onError: () => setDeleteLoading(false),
	})

	const [formData, setFormData] = React.useState(defaultFormData)

	function handleSetFormData(field) {
		return (e) =>
			setFormData((prevState) => ({
				...prevState,
				[field]: e.target.value,
			}))
	}

	function handleSubmit() {
		const payload = isNew ? formData : { id, ...formData }
		mutation.mutate(payload)
	}

	function handleDelete() {
		deleteMutation.mutate(id)
	}

	React.useEffect(() => {
		if (initialData) {
			setFormData(initialData)
		}
	}, [initialData])

	const [overrides, setOverrides] = React.useState({
		Input: {
			style: {
				fontSize: '14px',
				'@media screen and (max-width: 768px)': {
					fontSize: '14px',
				},
			},
		},
	})

	return (
		<FlexBlock padding="2rem .5rem" flexDirection="column">
			<FormControl label="템플릿 이름">
				<Input overrides={overrides} placeholder="템플릿 이름을 입력해주세요" id="name" name="name" required={true} value={formData.name} onChange={handleSetFormData('name')} />
			</FormControl>
			<FormControl label="이메일 제목">
				<Input overrides={overrides} placeholder="제목을 입력해주세요" id="subject" name="subject" required={true} value={formData.subject} onChange={handleSetFormData('subject')} />
			</FormControl>
			<FormControl
				label="이메일 본문"
				caption={() => (
					<ul style={{
						paddingLeft:'15px',
						marginTop:'20px'
					}}>
						<li>아래 내용이 치환되어 메일이 전송됩니다</li>
						<li>{'{클라이언트}: 의뢰한 회사 이름'}</li>
						<li>{'{담당자}: 의뢰한 회사의 담당자 이름'}</li>
						<li>{'{담당자:연락처}: 담당자 전화번호'}</li>
						<li>{'{담당자:이메일}: 담당자 이메일주소'}</li>
						<li>{'{의뢰명}: 의뢰한 프로젝트의 제목'}</li>
					</ul>
				)}
			>
				<Textarea
					placeholder="내용을 입력해주세요."
					name="body"
					value={formData.body}
					onChange={handleSetFormData('body')}
					overrides={{
						Input: {
							style: {
								minHeight: '40vh',
								resize: 'none',
								fontSize: '14px',
								'@media screen and (max-width: 768px)': {
									fontSize: '14px',
								},
							},
						},
					}}
				/>
			</FormControl>

			<Block marginTop="2rem">
				<Grid
					overrides={{
						Grid: {
							style: {
								paddingLeft: '0 !important',
								paddingRight: '0 !important',
							},
						},
					}}
				>
					<Cell span={[12, 12, 3]}>
						{!isNew && (
							<Button
								kind={KIND.primary}
								{...buttonProps}
								overrides={{
									BaseButton: {
										style: ({ $theme }) => ({
											backgroundColor: $theme.colors.negative500,
											width: '100%',
											':disabled': {
												outline: `${$theme.colors.buttonDisabledText} solid`,
											},
											'@media screen and (max-width: 1135px)': {
												marginBottom: '10px',
											},
										}),
									},
								}}
								onClick={() => setModalOpen(true)}
								isLoading={deleteLoading}
								disabled={!!initialData.is_default}
							>
								{!initialData.is_default ? '삭제' : '이 템플릿은 삭제할 수 없습니다'}
							</Button>
						)}
					</Cell>
					<Cell span={[12, 12, 3]} skip={[0, 0, 6]}>
						<Button {...buttonProps} onClick={handleSubmit} isLoading={submitLoading}>
							저장
						</Button>
					</Cell>
				</Grid>
			</Block>
			<Confirm
				isOpen={modalOpen}
				onClose={() => setModalOpen(false)}
				onConfirm={() => {
					handleDelete()
				}}
				body="이 템플릿을 정말 삭제하시겠습니까?"
			/>
		</FlexBlock>
	)
}

function EmailTemplateUpdateForm({ id }) {
	const { isLoading, isError, data = {} } = useQuery(['templateDetail', { id }], detailEmailTemplate)
	const { enqueue } = useSnackbar()
	const navigate = useNavigate()

	if (isError) {
		enqueue(
			{
				message: '유효하지 않은 접근입니다',
				overrides: { Content: { style: ({ $theme }) => ({ backgroundColor: $theme.colors.negative500 }) } },
				startEnhancer: ({ size }) => <DeleteAlt size={size} />,
			},
			DURATION.short
		)
		navigate(routes.emailTemplates)
		return <></>
	}

	if (isLoading) {
		return (
			<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
				<Spinner $size="50px" $borderWidth="5px" $color="#000" />
			</FlexBlock>
		)
	}
	return <EmailTemplateForm id={id} initialData={data} />
}

function EmailTemplateCreateForm() {
	return <EmailTemplateForm />
}

export default function EmailTemplateDetail({ props }) {
	const { id } = useParams()
	const isNew = !id
	return (
		<Layout>
			<ContentHeader
				title={'이메일 템플릿 상세'}
				// actions={<Button size={SIZE.compact} onClick={handleClick} >템플릿 추가 +</Button>}
			/>
			<Block maxWidth="1200px">{isNew ? <EmailTemplateCreateForm /> : <EmailTemplateUpdateForm id={id} />}</Block>
		</Layout>
	)
}
