import {StatefulMenu} from "baseui/menu";
import {Checkbox} from "baseui/checkbox";
import {PLACEMENT, StatefulPopover} from "baseui/popover";
import {Button, KIND} from "baseui/button";
import {TriangleDown} from "baseui/icon";
import React, {useRef} from "react";
import {useStyletron} from "baseui";


export default function CheckboxPopover ({title, data, onChange}) {
    const [, theme] = useStyletron(); // eslint-disable-line
    const checkBoxRefs = useRef([]);

    const [checkboxes, setCheckboxes] = React.useState(dataToCheckboxObject(data));
    const [initialized, setInitialized] = React.useState(false);
    const allChecked = Object.values(checkboxes).every(Boolean);
    const isIndeterminate = Object.values(checkboxes).some(Boolean) && !allChecked;

    function dataToCheckboxObject (_data) {
        const obj = {}
        for (let i=0; i < data.length; i++) {
            obj[data[i].value] = true;
        }
        return obj;
    }

    function handleAllChange(e) {
        setCheckboxes(prevState => {
            for (let k in prevState) {
                prevState[k] = e.target.checked;
            }
            return {...prevState};
        })
    }
    function handleChange (e) {
        setCheckboxes(prevState => ({...prevState, [e.target.value]: e.target.checked}))
    }

    React.useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        } else {
            onChange && onChange(checkboxes, allChecked);
        }
    }, [checkboxes])


    function* checkboxGenerator () {
        yield (
            <Checkbox
                checked={allChecked}
                value={''}
                overrides={{
                    Checkmark: {style: {width: '18px', height: '18px'}},
                    Label: {style: {...theme.typography.font200}}
                }}
                isIndeterminate={isIndeterminate}
                inputRef={(el) => (checkBoxRefs.current[0] = el)}
                onChange={handleAllChange}
                children="전체"
            >
            </Checkbox>
        )
        for (let i=0; i < data.length; i++) {
            const row = data[i];
            yield (
                <Checkbox
                    name="state"
                    checked={checkboxes[row.value]}
                    key={row.value}
                    value={row.value}
                    overrides={{
                        Checkmark: {style: {width: '18px', height: '18px'}},
                        Label: {style: {...theme.typography.font200}}
                    }}
                    onChange={handleChange}
                    children={row.label}
                />
            )
        }
    }
    const gen = checkboxGenerator();
    const checkboxItems = Array.from({length: data.length+1}, () => gen.next().value)

    return (
        <StatefulPopover
            content={() => <StatefulMenu items={checkboxItems.map(row => ({label: row}))} />}
            returnFocus
            autoFocus
            placement={PLACEMENT.bottomRight}
            overrides={{
                Body: {
                    style: {
                        boxShadow: 'unset',
                        ...theme.borders.border100,
                        width: '120px'
                    }
                },
                Inner: {
                    style: {
                        backgroundColor: theme.colors.backgroundPrimary
                    }
                }
            }}
        >
            <Button
                kind={KIND.tertiary}
                endEnhancer={<TriangleDown size={18} />}
                overrides={{
                    BaseButton: {style: {...theme.typography.font200, paddingLeft: '0', paddingRight: '0', paddingTop: '0', paddingBottom: '0'}},
                    EndEnhancer: {style: {marginLeft: '6px'}}
                }}
            >
                {title}
            </Button>
        </StatefulPopover>
    )
}