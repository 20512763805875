import { KIND, Tag, VARIANT } from 'baseui/tag'
import { humanizeStatus } from '../core/utils'
import React from 'react'

function statusToTagProps(status) {
	switch (status) {
		case 'new':
			return { kind: KIND.primary, variant: VARIANT.outlined }
		case 'aligning':
			return { kind: KIND.primary, variant: VARIANT.solid }
		case 'in_progress':
			return { kind: KIND.positive, variant: VARIANT.outlined }
		case 'completed':
			return { kind: KIND.positive, variant: VARIANT.solid }
		case 'rejected':
			return { kind: KIND.warning, variant: VARIANT.solid }
		case 'cancelled':
			return { kind: KIND.negative, variant: VARIANT.solid }
		default:
			return { kind: KIND.primary, variant: VARIANT.outlined }
	}
}

export default function StatusTag({ status, size }) {
	const props = statusToTagProps(status || undefined)
	return (
		<Tag
			closeable={false}
			size={size}
			overrides={{
				Root: {
					style: {
						width: '45px',
						minWidth: 'max-content',
						justifyContent: 'center',
						boxSizing: 'border-box',
						fontSize: '14px',
						marginLeft: 0,
						'@media only screen and (max-width: 768px)': {
							width: '42px',
							fontSize: '12px',
						},
					},
				},
			}}
			{...props}
		>
			{humanizeStatus(status)}
		</Tag>
	)
}
