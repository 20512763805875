import { listPaperLogs } from '../../core/apis'
import { useQuery } from 'react-query'
import FlexBlock from '../FlexBlock'
import { Spinner } from 'baseui/spinner'
import React from 'react'
import { Table } from 'baseui/table-semantic'
import spacetime from 'spacetime'

export default function LogList({ id }) {
	const { data, isLoading } = useQuery(['logs', { id }], listPaperLogs)
	const { results } = data || {}

	if (isLoading) {
		return (
			<FlexBlock justifyContent="center" alignItems="center" width="100%" height="500px">
				<Spinner $size="50px" $borderWidth="5px" $color="#000" />
			</FlexBlock>
		)
	}

	function parseDatetime(strDatetime) {
		return spacetime(strDatetime).unixFmt('yyyy-MM-dd h:mm a')
	}

	return (
		<FlexBlock width="100%">
			<Table
				columns={['날짜/시간', '유저', '내용']}
				data={results.map((row) => [parseDatetime(row.created_datetime), row.user.name || '자동생성', row.message])}
				overrides={{
					Root: {
						style: {
							width: '100%',
						},
					},
					TableBodyCell: {
						style: {
							fontSize: '14px',
							wordBreak: 'keep-all',
							'@media screen and (max-width: 768px)': {
								fontSize: '12px',
							},
						},
					},
					TableHeadCell: {
						style: {
							fontSize: '14px',
							'@media screen and (max-width: 768px)': {
								fontSize: '12px',
							},
						},
					},
				}}
			/>
		</FlexBlock>
	)
}
